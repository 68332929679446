<template>
  <div class="wrapper d-flex align-items-center justify-content-center">
    <hs-loading v-if="isLoading"></hs-loading>
    <div v-show="!isLoading" id="gjs">
      <mjml v-pre>
        <mj-body> </mj-body>
      </mjml>
    </div>
  </div>
</template>

<script>
import grapesjs from 'grapesjs';
import grapesjsMjml from 'grapesjs-mjml';
import pt from 'grapesjs/locale/pt';
import mjmlPt from 'grapesjs-mjml/locale/pt';
import {
  commands,
  tags,
  events,
  panels,
  panelButtons,
  sectors,
  propertyTypes,
  propertyUnits,
} from '@/shared/helpers/grapes';
import { hsLoading } from '@/components';
import { mapMutations, mapState } from 'vuex';
import { uploadService, emailService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';

export default {
  components: {
    hsLoading,
  },
  computed: {
    ...mapState('email', ['mjml', 'variables']),
    ...mapState('school', ['selectedSchool']),
    ...mapState('auth', ['loggedUser']),
    s3Config() {
      return {
        key: `accounts/${this.selectedSchool.id}`,
        bucket: process.env.VUE_APP_SPARKFUNNELS_ASSETS_BUCKET,
      };
    },
  },
  data() {
    return {
      isLoading: true,
      editor: null,
      email: null,
    };
  },
  mounted() {
    this.initializeEditor();
  },
  methods: {
    ...mapMutations('email', ['setMjml', 'setHtml']),
    backToCampaignConfig() {
      this.$router.push({ name: 'CampaignCreate' });
    },
    initializeEditor() {
      this.isLoading = true;
      this.editor = grapesjs.init({
        container: '#gjs',
        height: '100vh',
        width: '100vw',
        i18n: {
          locale: 'pt',
          localeFallback: 'en',
          messages: {
            pt,
          },
        },
        assetManager: {
          uploadName: 'files',
          autoAdd: false,
          assets: [],
          uploadFile: this.upload,
        },
        forceClass: false,
        storageManager: false,
        avoidInlineStyle: false,
        plugins: [grapesjsMjml],
        pluginsOpts: {
          grapesjsMjml: {
            i18n: { pt: mjmlPt },
          },
        },
        fromElement: 1,
        colorPicker: {
          preferredFormat: 'hex',
        },
      });

      this.editor.on(events.EDITOR_LOADED, () => {
        this.isLoading = false;
        this.onEditorLoaded();
      });
    },
    async loadEmailAssets() {
      const { data } = await uploadService.getAwsKeysByBucket({
        contentType: 'image/png',
        bucket: this.s3Config.bucket,
        key: this.s3Config.key,
      });
      const url = data.url;
      uploadService
        .listObjects({ url, prefix: this.s3Config.key })
        .then(images => this.editor.AssetManager.add(images));
    },
    async upload({ target }) {
      const file = target.files[0];
      if (file) {
        const { data } = await uploadService.getAwsKeysByBucket({
          contentType: file.type,
          bucket: this.s3Config.bucket,
          key: this.s3Config.key,
        });
        const uploadedImage = await uploadService.upload(data.url, data.fields, file);
        this.editor.AssetManager.add(uploadedImage);
      }
    },
    /**
     * @description careful: sequence of method calls matter here.
     */
    onEditorLoaded() {
      this.forceEnclosingTags();
      this.overridePaddingProperty();
      this.openBlocks();
      this.setBlockDropBehaviour();
      this.setUpdateBehaviour();
      this.loadEmailAssets();
      this.setStyles();
      this.addVariables();
      this.setContent();
      this.setSelectionBehaviour();
      this.customizeButtons();
      this.addBodyPadding();
    },
    addBodyPadding() {
      this.editor.DomComponents.getWrapper().set('style', { 'padding-bottom': '30px' });
    },
    overridePaddingProperty() {
      this.editor.StyleManager.removeProperty(sectors.DIMENSION, 'padding');
      this.editor.StyleManager.addProperty(sectors.DIMENSION, {
        property: 'padding',
        type: propertyTypes.COMPOSITE,
        properties: ['Top', 'Bottom', 'Left', 'Right'].map(position => ({
          name: position,
          property: `padding-${position.toLowerCase()}`,
          type: propertyTypes.INTEGER,
          units: [propertyUnits.PIXELS, propertyUnits.PERCENTAGE, propertyUnits.ELEMENT_RELATIVE],
        })),
      });
    },
    openBlocks() {
      const blockBtn = this.editor.Panels.getButton(panels.VIEWS, panelButtons.VIEWS.OPEN_BLOCKS);
      blockBtn.set('active', 1);
    },
    openStyles() {
      const blockBtn = this.editor.Panels.getButton(panels.VIEWS, panelButtons.VIEWS.OPEN_STYLES);
      blockBtn.set('active', 1);
    },
    openTraits() {
      const blockBtn = this.editor.Panels.getButton(panels.VIEWS, panelButtons.VIEWS.OPEN_TRAITS);
      blockBtn.set('active', 1);
    },
    customizeButtons() {
      this.editor.Panels.removeButton(panels.VIEWS, panelButtons.VIEWS.OPEN_LAYERS);
      this.editor.Panels.removeButton(panels.OPTIONS, panelButtons.OPTIONS.IMPORT_MJML);

      this.editor.Panels.getPanel(panels.OPTIONS)
        .get('buttons')
        .add([
          {
            id: panelButtons.OPTIONS.SEND_TEST_EMAIL,
            className: 'fal fa-paper-plane',
            command: { run: this.sendTestEmail },
            attributes: { title: this.$t('email.actions.send_test_email.title', { email: this.loggedUser.email }) },
            active: false,
          },
          {
            id: panelButtons.OPTIONS.SAVE,
            className: 'far fa-save',
            command: { run: () => this.backToCampaignConfig() },
            attributes: { title: this.$t('email.actions.save.title') },
            active: false,
          },
        ]);
    },
    setStyles() {
      this.editor.setStyle(
        '.mention, code { padding: 3px; border-radius: 5px; background: whitesmoke; font-weight: bold }'
      );
    },
    setContent() {
      if (this.mjml) {
        this.editor.setComponents(this.mjml);
      }
    },
    sendTestEmail() {
      const body = this.editor.runCommand(commands.MJML.GET_CODE).html;
      emailService
        .sendTestEmail({ to: this.loggedUser.email, subject: this.$t('email.actions.send_test_email.subject'), body })
        .then(() => ToastHelper.successMessage(this.$t('email.actions.send_test_email.success')))
        .catch(() => ToastHelper.dangerMessage(this.$t('email.actions.send_test_email.error')));
    },
    setBlockDropBehaviour() {
      this.editor.on(events.BLOCK_DRAG_STOP, model => {
        if (!model) return;
        this.editor.select(model);
        switch (model.attributes.tagName) {
          case tags.IMAGE:
            this.editor.runCommand(commands.GENERAL.OPEN_ASSETS, { target: this.editor.getSelected() });
            break;
          default:
            this.openStyles();
            break;
        }
      });
    },
    setUpdateBehaviour() {
      this.editor.on(events.UPDATE, () => {
        this.setMjml(this.editor.getHtml());
        this.setHtml(this.editor.runCommand(commands.MJML.GET_CODE).html);
      });

      // TODO: 'update' event on listener above is not triggering trait changes.
      // Remove the listener bellow when this bug is fixed.
      this.editor.on('component:update', model => {
        const modelAttributes = model.getAttributes();

        if (modelAttributes?.class === 'link') {
          this.setMjml(this.editor.getHtml());
          this.setHtml(this.editor.runCommand(commands.MJML.GET_CODE).html);
        }
      });
    },
    setSelectionBehaviour() {
      this.editor.on(events.COMPONENT_SELECTED, model => {
        if (model && model.attributes) {
          switch (model.attributes.tagName) {
            case tags.LINK:
            case tags.SOCIAL_ITEM:
              this.openTraits();
              break;
            case tags.BODY:
              this.openBlocks();
              break;
            default:
              this.openStyles();
              break;
          }
        }
      });
    },
    /**
     * @description Forces tags to have an enclosing tag instead of self-closing.
     * @see https://github.com/artf/grapesjs-mjml/issues/149
     */
    forceEnclosingTags() {
      [tags.IMAGE, tags.SPACER, tags.DIVIDER].forEach(tag => {
        this.editor.DomComponents.addType(tag, { model: { defaults: { void: false } } });
      });
    },
    addVariables() {
      const rte = this.editor.RichTextEditor;
      rte.add('variables', {
        icon: `<select class="gjs-field" style="color: white">
          <option selected value="">Variáveis</option>
          ${this.variables.map(variable => `<option value="{{ ${variable.value} }}">${variable.text}</option>`)}
        </select>`,
        event: 'change',
        result: (rte, action) =>
          rte.exec(
            'insertHtml',
            `<span variable-id="${action.btn.firstChild.value}" class="mention">${action.btn.firstChild.value}</span>`
          ),
        update: (rte, action) => {
          const value = rte.doc.queryCommandValue(action.name);
          if (value != 'false') {
            action.btn.firstChild.value = value;
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100vw;
  height: 100vh;
}

#gjs /deep/ {
  .fa-trash-o:before {
    content: '\f1f8';
  }

  .fa-repeat:before {
    content: '\f01e';
  }

  .fa-square-o:before {
    content: '\f850';
  }

  .fa-arrows-alt:before {
    content: '\f320';
  }

  .gjs-pn-btn.fa-paper-plane {
    order: -1;
  }

  .gjs-pn-btn.fa-save {
    order: -2;
  }
}
</style>
