export const commands = Object.freeze({
  CORE: {
    UNDO: 'core:undo',
    REDO: 'core:redo',
    PREVIEW: 'core:preview',
    RESIZE: 'core:resize',
    FULLSCREEN: 'core:fullscreen',
    COPY: 'core:copy',
    PASTE: 'core:paste',
    CANVAS_MOVE: 'core:canvas-move',
    CANVAS_CLEAR: 'core:canvas-clear',
    OPEN_CODE: 'core:open-code',
    OPEN_LAYERS: 'core:open-layers',
    OPEN_STYLES: 'core:open-styles',
    OPEN_TRAITS: 'core:open-traits',
    OPEN_BLOCKS: 'core:open-blocks',
    OPEN_ASSETS: 'core:open-assets',
    SELECT_COMPONENT: 'core:component-select',
    OUTLINE_COMPONENT: 'core:component-outline',
    SHOW_OFFSET: 'core:component-offset',
    MOVE_COMPONENT: 'core:component-move',
    NEXT_COMPONENT: 'core:component-next',
    PREVIOUS_COMPONENT: 'core:component-prev',
    ENTER_COMPONENT: 'core:component-enter',
    EXIT_COMPONENT: 'core:component-exit',
    DELETE_COMPONENT: 'core:component-delete',
    CLEAR_COMPONENT_STYLE: 'core:component-style-clear',
    DRAG_COMPONENT: 'core:component-drag',
  },
  MJML: {
    IMPORT: 'mjml-import',
    IMPORT_CHANGE: 'mjml-import:change',
    GET_CODE: 'mjml-get-code',
  },
  GENERAL: {
    PREVIEW: 'preview',
    RESIZE: 'resize',
    FULLSCREEN: 'fullscreen',
    EXPORT_TEMPLATE: 'export-template',
    OPEN_LAYERS: 'open-layers',
    OPEN_STYLES_MANAGER: 'open-sm',
    OPEN_TRAITS_MANAGER: 'open-tm',
    OPEN_BLOCKS: 'open-blocks',
    OPEN_ASSETS: 'open-assets',
    SELECT_COMPONENT: 'select-comp',
    SHOW_OFFSET: 'show-offset',
    MOVE_COMPONENT: 'move-comp',
    SELECT_PARENT: 'select-parent',
    UNDO: 'undo',
    REDO: 'redo',
    SET_DEVICE_DESKTOP: 'set-device-desktop',
    SET_DEVICE_TABLET: 'set-device-tablet',
    SET_DEVICE_MOBILE: 'set-device-mobile',
  },
});

export const tags = Object.freeze({
  ONE_COLUMN: 'mj-1-column',
  TWO_COLUMNS: 'mj-2-columns',
  THREE_COLUMNS: 'mj-3-columns',
  TEXT: 'mj-text',
  BUTTON: 'mj-button',
  BODY: 'mj-body',
  IMAGE: 'mj-image',
  DIVIDER: 'mj-divider',
  SOCIAL_GROUP: 'mj-social-group',
  SOCIAL_ITEM: 'mj-social-element',
  SPACER: 'mj-spacer',
  NAVBAR: 'mj-navbar',
  NAVBAR_ITEM: 'mj-navbar-link',
  HERO: 'mj-hero',
  WRAPPER: 'mj-wrapper',
  LINK: 'a',
});

export const events = Object.freeze({
  BLOCK_DRAG_STOP: 'block:drag:stop',
  EDITOR_LOADED: 'load',
  UPDATE: 'update',
  COMPONENT_SELECTED: 'component:selected',
});

export const panels = Object.freeze({
  VIEWS: 'views',
  OPTIONS: 'options',
});

export const panelButtons = Object.freeze({
  VIEWS: {
    OPEN_LAYERS: 'open-layers',
    OPEN_TRAITS: 'open-tm',
    OPEN_STYLES: 'open-sm',
    OPEN_BLOCKS: 'open-blocks',
  },
  OPTIONS: {
    SAVE: 'save',
    SEND_TEST_EMAIL: 'send-test-email',
    IMPORT_MJML: 'mjml-import',
    UNDO: 'undo',
    REDO: 'redo',
  },
});

export const sectors = Object.freeze({
  DIMENSION: 'dimension',
});

export const propertyTypes = Object.freeze({
  INTEGER: 'integer',
  COMPOSITE: 'composite',
});

export const propertyUnits = Object.freeze({
  PIXELS: 'px',
  PERCENTAGE: '%',
  ELEMENT_RELATIVE: 'em',
  ROOT_ELEMENT_RELATIVE: 'rem',
});
